import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";
import Section from "../../components/Section";
import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Headline from "../../components/Headline";
import { getWLSUser, setWLSUser } from "../../services/utility";
import Form from "react-bootstrap/Form";
import axios from 'axios';
import { stringify } from 'qs';

export default class WLS2021Reg extends React.Component {
  constructor(props) {
    super(props)
    let wlsUser = getWLSUser();
    this.state = {
      firstName: wlsUser.firstName,
      lastName: wlsUser.lastName,
      email: wlsUser.email,
      tribe: wlsUser.tribe,
      optin: wlsUser.optin,
      isRegistered: (wlsUser.email ? true : false)
    }
    this.register = this.register.bind(this);
    this.clearRegistered = this.clearRegistered.bind(this);
    this.firstNameChange = this.firstNameChange.bind(this);
    this.lastNameChange = this.lastNameChange.bind(this);
    this.emailChange = this.emailChange.bind(this);
    this.tribeChange = this.tribeChange.bind(this);
    this.optinChange = this.optinChange.bind(this);
  }

  clearRegistered(event) {
    if(event) {
      event.preventDefault();
    }
    this.setState({ isRegistered: false });
    setWLSUser(undefined);
  }

  register(event) {
    if(event) {
      event.preventDefault();
    }
    setWLSUser({
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      tribe: this.state.tribe,
      optin: this.state.optin
    });

    let axiosInstance = axios.create({
      timeout: 45000,
      headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    });

    let data = stringify({
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      tribe: this.state.tribe,
    });

    let url = this.state.optin ? 'https://info.oneagleswings.com/l/836243/2021-05-28/919kx' : 'https://info.oneagleswings.com/l/836243/2021-05-28/919l2';

    axiosInstance.post(url, data)
    .then(() => {
      window.location.href = '/events/wls-2021';
      this.setState({ isRegistered: true });
    })
    .catch(() => {
      // Silent errors
      window.location.href = '/events/wls-2021';
      this.setState({ isRegistered: true });
    });
  }

  firstNameChange(event) {
    if(event && event.target) {
      this.setState({ firstName: event.target.value });
    }
  }

  lastNameChange(event) {
    if(event && event.target) {
      this.setState({ lastName: event.target.value });
    }
  }
  
  emailChange(event) {
    if(event && event.target) {
      this.setState({ email: event.target.value });
    }
  }

  tribeChange(event) {
    if(event && event.target) {
      this.setState({ tribe: event.target.value });
    }
  }

  optinChange(event) {
    if(event && event.target) {
      this.setState({ optin: event.target.checked });
      alert(event.target.checked);
    }
  }

  render() {
    return ( 
      <Layout transparentNav={true} darkNav={true} className="wls2021">
        <Seo title="Stronger - WLS 2021 - Livestream Registration" />
        <Section className="wls2021SessionSection dark mt-5 pt-5" useContainer={false}>
          <Container id="sessions">
            {this.state.isRegistered && <Row className="justify-content-center text-center">
              <Col xs={12} md={10} lg={8}>
                <Headline className="pt-3 pb-5" center={true}>Livestream Registration</Headline>
                <div>
                  You are already registered as {this.state.firstName} {this.state.lastName}. <a href="./" className="body-link" onClick={this.clearRegistered}>Not {this.state.firstName}</a>?
                </div>
              </Col>
            </Row>}

            {!this.state.isRegistered && <Row className="justify-content-center text-center">
              <Col xs={12} md={10} lg={8}>
                <Headline center={true}>Livestream Registration</Headline>
                <p>Unable to join us in person this year?  
                  Register to watch WLS online. By registering, you will be set to join us online for the evening livestreams at WLS 2021, 
                  and you are entered for <strong>giveaways</strong> that will take place during WLS!
                  You will also receive occasional email updates about WLS.</p>

                <Form method="POST" onSubmit={event => { this.register(event) }}>
                  <Form.Group controlId="firstName">
                    <Form.Label>First Name *</Form.Label>
                    <Form.Control required type="text" placeholder="First Name" name="firstName" defaultValue={this.state.firstName} onChange={this.firstNameChange} />
                  </Form.Group>
                  <Form.Group controlId="lastName">
                    <Form.Label>Last Name *</Form.Label>
                    <Form.Control required type="text" placeholder="Last Name" name="lastName" defaultValue={this.state.lastName} onChange={this.lastNameChange} />
                  </Form.Group>
                  <Form.Group controlId="email">
                    <Form.Label>Email *</Form.Label>
                    <Form.Control required type="email" placeholder="Email" name="email" defaultValue={this.state.email} onChange={this.emailChange} />
                    <Form.Text>
                      We'll never share your email with anyone else.
                    </Form.Text>
                  </Form.Group>
                  <Form.Group controlId="tribe">
                    <Form.Label>Tribe / Where you're watching from</Form.Label>
                    <Form.Control type="text" placeholder="Optional" name="tribe" defaultValue={this.state.tribe} onChange={this.tribeChange} />
                  </Form.Group>
                  <Form.Group controlId="optIn">
                    <Form.Check type="checkbox" name="optin" id="optin" label="Receive WLS Updates" defaultChecked={this.state.optin} onChange={this.optinChange} />
                    <Form.Text>
                      Receive key info on WLS, including upcoming events, special guests and other news. Frequency is approximately 1-2 times a month.
                    </Form.Text>
                  </Form.Group>
                  <Button variant="primary" type="submit" className="mt-3 mb-4">
                    <b>Register</b>
                  </Button>
                </Form>
              </Col>
            </Row>}

          </Container>
        </Section>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    ministryEvent: contentfulMinistryEvent(slug: { eq: "wls-2021" }) {
      ...MinistryEvent
      sessions {
        ...MinistryEventSession
      }
    }
  }
`
